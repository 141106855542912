@import '../../styles/helpers/index.scss';

.call-us {
  margin: 140px 0 0;
  padding: 130px 0 40px;

  background-image: url('../../static/images/backgrounds/call_us-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    padding: 65px 0;
  }

  .section-title {
    @media screen and (max-width: $tablet-sm) {
      br {
        display: none;
      }
    }
  }

  p {
    margin: 25px 0 0;
    font-size: 24px;
    line-height: 150%;
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      font-size: 16px;

      br {
        display: none;
      }
    }
  }

  &__links {
    margin: 45px 0 0;
    @include align-center;

    a {
      border-radius: 0;
    }

    span {
      font-weight: 600;
      font-size: 30px;
      line-height: 150%;
      margin: 0 0 0 20px;
      color: #fff;

      @media screen and (max-width: $tablet-sm) {
        font-size: 20px;
      }
    }
  }

  &__payments {
    margin: 35px 0 0;
    @include align-center;
    justify-content: flex-end;

    @media screen and (max-width: $tablet-sm) {
      justify-content: space-around;
    }

    img {
      @include scale;
      @media screen and (max-width: $tablet-sm) {
        height: 40px;
      }
      &:not(:last-of-type) {
        margin: 0 20px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }
      }
    }
  }
}
