@import '../../styles/helpers/index.scss';

.page-footer {
  background-color: map-get($colors, 'text');
  color: #fff;

  padding: 96px 0 24px;

  @media screen and (max-width: $tablet-sm) {
    padding: 96px 0;
  }

  & > .container {
    @include align-center;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;
      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }
  }

  &__content {
    p {
      max-width: 490px;
      font-size: 18px;
      line-height: 150%;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      align-items: flex-start;
    }

    a {
      display: block;
      color: #fff;
    }

    &--phone {
      margin: 0 0 50px;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;

      position: relative;
      @include shine;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 30px;
        font-size: 32px;
        line-height: 1.24;
      }
    }

    &--email {
      margin: 0 0 40px;
      font-size: 24px;
      line-height: 36px;

      position: relative;
      @include shine;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 30px;
        font-size: 18px;
        line-height: 1.24;
      }
    }

    &--address {
      font-size: 18px;
      line-height: 200%;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }

      @media screen and (max-width: $tablet-sm) {
        line-height: 1.24;
        font-size: 16px;
      }
    }
  }

  &__logo {
    margin: 0 0 45px;
    display: block;
    height: 70px;

    img {
      height: inherit;
      width: auto;
      object-fit: cover;
    }
  }

  &__underline {
    margin: 70px 0 0;

    .container {
      padding-top: 24px;
      border-top: 1px solid #fff;

      @include align-center;
      flex-direction: column;

      font-size: 12px;
      line-height: 180%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }

      & > div {
        width: 100%;
        @include space-between;
        margin: 0 0 30px;

        @media screen and (max-width: $tablet-sm) {
          flex-direction: column;
          text-align: center;
        }
      }

      & > a {
        font-size: 14px;
        color: #fff;
        strong {
          font-weight: bold;
        }

        &:hover {
          strong {
            text-decoration: underline;
          }
        }
      }
    }

    &--links {
      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
      }
      a {
        color: #fff;
        &:not(:last-of-type) {
          margin: 0 10px 0 0;
        }

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
