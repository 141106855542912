@import '../../styles/helpers/index.scss';

.about {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__inner {
    @include grid;
  }

  &__content {
    div {
      margin: 40px 0 45px;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0;
      }

      p {
        font-size: 18px;
        line-height: 150%;
        color: map-get($colors, 'text');
        &:not(:last-of-type) {
          margin: 0 0 15px;
        }
      }
    }

    .green-text {
      color: map-get($colors, 'green');
      font-size: 24px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
      }
    }
  }

  &__image {
    min-height: 730px;
    height: inherit;
    // max-width: 60%;
    // width: 100%;
    background-image: url('../../static/images/backgrounds/about-bg_desktop.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
    @include shine;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }
}
