@import '../../styles/helpers/index.scss';

.awards {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 50px 0 0;
  }

  .container {
    @include align-center;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-wrap: wrap;
    }
  }

  &__card {
    height: 110px;
    position: relative;

    @include shine;

    @media screen and (max-width: $tablet-sm) {
      height: 70px;
      margin: 0 0 20px;
    }

    img {
      height: inherit;
      object-fit: cover;
    }
  }
}
