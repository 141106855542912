@import '../../styles/helpers/index.scss';

.order-form {
  margin: 45px 0 40px;

  @media screen and (max-width: $tablet-sm) {
  }

  & > div {
    @include grid($columns: repeat(3, 1fr), $row-gap: 20px);
  }

  p {
    margin: 30px 0 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }

  input,
  select {
    width: 100%;
    height: 50px;
    border: none;
    padding: 12px 25px;
    outline: none;
    appearance: none;
    font-size: 14px;
    color: map-get($colors, 'text');
    background-color: #fff;

    &:disabled {
      opacity: 0.7;
      background-color: rgba(#fff, 0.5);
      user-select: none;
      color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
    }

    &::placeholder {
      color: map-get($colors, 'text');
    }
  }

  input {
    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .btn.white {
    margin: 40px 0 0;
    max-width: 180px;
    height: auto;
    color: #fff;
    font-size: 16px;
    &:disabled {
      cursor: not-allowed;
      user-select: none;
      opacity: 0.5 !important;
      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
