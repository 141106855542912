@import '../../styles/helpers/index.scss';

.get-your-quote {
  margin: 140px 0 0;
  padding: 120px 0;

  background-color: map-get($colors, 'green');

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
    padding: 60px 0;
  }
}
