@import '../../styles/helpers/index.scss';

.hero {
  &__mobile {
    min-height: 250px;
    background-image: url('../../static/images/backgrounds/hero-bg_mobile.png');
    background-size: cover;
    background-position: 0 35%;
    background-repeat: no-repeat;

    display: none;

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }
  }

  &__inner {
    display: flex;

    &--content {
      padding: 100px 0 0;

      max-width: 40%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        padding: 30px 0 0;
      }

      h1 {
        font-weight: 700;
        font-size: 72px;
        line-height: 110%;
        text-transform: uppercase;
        color: map-get($colors, 'text');

        @media screen and (max-width: $tablet-sm) {
          font-size: 32px;
          line-height: 116%;

          br {
            display: none;
          }
        }
      }

      p {
        margin: 30px 0 0;
        font-size: 40px;
        line-height: 120%;
        color: map-get($colors, 'green');

        @media screen and (max-width: $tablet-sm) {
          font-size: 28px;
        }
      }

      &__buttons {
        margin: 70px 0 0;
        @include align-center;

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }

        .black {
          margin: 0 0 0 20px;
        }
      }
    }

    &--image {
      max-width: 60%;
      width: 100%;
      background-image: url('../../static/images/backgrounds/hero-bg_desktop.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      position: relative;
      @include shine;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }
}
