@import '../../styles/helpers/index.scss';

.why-we {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__badges {
    margin: 45px 0 0;
    @include grid($columns: repeat(4, 1fr));

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
    }

    &--item {
      position: relative;
      padding: 0 35px;
      @include align-center;
      justify-content: center;
      flex-direction: column;
      height: 280px;
      width: 280px;
      background-color: map-get($colors, 'background');

      @include shine;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 auto;
        width: 100%;
      }

      div {
        height: 100px;
        @include align-center;
      }

      p {
        margin: 35px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: map-get($colors, 'text');
        text-align: center;
      }
    }
  }
}
