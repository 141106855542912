@import '../../styles/helpers/index.scss';

.satisfaction {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__banners {
    margin: 45px 0 0;

    @include grid($columns: repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
    }

    &--item {
      height: 220px;
      @include align-center;
      justify-content: center;
      flex-direction: column;

      background-color: map-get($colors, 'background');

      @include scale;

      &__stars {
        margin: 30px 0 0;
        display: flex;

        img {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
