@import '../../styles/helpers/index.scss';

.counter {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    @include grid($columns: repeat(3, 1fr));

    &--item {
      text-align: center;
      position: relative;
      @include shine;

      &:nth-of-type(2) {
        border-right: 2px solid map-get($colors, 'green');
        border-left: 2px solid map-get($colors, 'green');

        @media screen and (max-width: $tablet-sm) {
          border: none;
        }
      }

      div {
        img {
          margin: 0 auto;
          height: 80px;
          width: auto;
          object-fit: cover;
        }
      }

      span {
        display: block;
        margin: 25px 0 0;
        font-weight: 700;
        font-size: 60px;
        line-height: 120%;
        text-transform: uppercase;
        color: map-get($colors, 'green');
      }

      p {
        font-size: 24px;
        line-height: 150%;
        color: map-get($colors, 'text');
      }
    }
  }
}
