@import './helpers/reset.scss';
@import './helpers/variables.scss';
@import './helpers/mixins.scss';

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Poppins', sans-serif;
  color: map-get($colors, 'text');

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding-top: 110px;
    @media screen and (max-width: $tablet-sm) {
      padding-top: 118px;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.btn {
  position: relative;
  display: inline-block;
  padding: 14px 20px;
  cursor: pointer;

  background-color: map-get($colors, 'green');
  border-radius: 50px;

  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  color: #fff;

  overflow: hidden;

  transition: all 0.3s ease;

  @include shine;

  @media screen and (max-width: $tablet-sm) {
    padding: 12px 15px;
    font-size: 16px;
    line-height: 100%;
  }

  &.white {
    padding: 12px 20px;
    background-color: transparent;
    border: 2px solid #fff;

    &:hover {
      background-color: #fff;
      color: map-get($colors, 'text');
    }
  }

  &.black {
    padding: 12px 20px;
    background-color: transparent;
    border: 2px solid map-get($colors, 'text');
    color: map-get($colors, 'text');

    &:hover {
      background-color: map-get($colors, 'text');
      color: #fff;
    }
  }

  &.phone {
    padding-left: 50px;

    @media screen and (max-width: $tablet-sm) {
      padding: 12px 15px;
      text-align: center;

      &::before {
        display: none;
      }
    }

    &.white {
      &::before {
        background-image: url('../static/images/icons/phone_white.svg');

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }

      &:hover {
        &::before {
          background-image: url('../static/images/icons/phone_black.svg');
        }
      }
    }

    &.black {
      &:hover {
        &::before {
          background-image: url('../static/images/icons/phone_white.svg');
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      height: 20px;
      width: 20px;

      background-image: url('../static/images/icons/phone_black.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (max-width: $tablet-sm) {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.section-title {
  font-weight: 700;
  font-size: 54px;
  line-height: 120%;
  text-transform: uppercase;
  color: map-get($colors, 'text');

  @media screen and (max-width: $tablet-sm) {
    font-size: 32px;
  }

  &.white {
    color: #fff;
  }
}

.mobile-br {
  display: none;
  @media screen and (max-width: $tablet-sm) {
    display: inline-block;
  }
}

.book-now {
  padding: 140px 0;

  background-color: map-get($colors, 'green');

  @media screen and (max-width: $tablet-sm) {
    padding: 70px 0;
  }
}
