@import '../../styles/helpers/index.scss';

.services {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 45px 0 0;
    @include grid($columns: repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
    }

    &--item {
      position: relative;
      width: 100%;
      height: 385px;
      @include align-center;
      justify-content: center;
      flex-direction: column;
      background-color: map-get($colors, 'green');

      @include shine;

      div {
        height: 140px;
      }

      p {
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        color: #fff;
      }
    }
  }
}
