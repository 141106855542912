@import '../../styles/helpers/index.scss';

.page-header {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: map-get($colors, 'text');
  color: #fff;

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
  }

  @media screen and (max-width: $tablet-sm) {
    padding: 12px 0;
  }

  .container {
    @include align-center;
    justify-content: space-between;
    @media screen and (max-width: $tablet-sm) {
      & > a {
        max-width: 50%;
        width: 100%;
      }
      & > div {
        max-width: 50%;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__logo {
    display: block;
    height: 70px;

    img {
      height: inherit;
      width: auto;
    }

    @media screen and (max-width: $tablet-sm) {
      height: 80px;
    }
  }

  &__buttons {
    @include align-center;
    a {
      display: inline-block;

      @media screen and (max-width: $tablet-sm) {
        min-width: 170px;
        text-align: center;
      }
    }

    .btn {
      &.white {
        margin: 0 20px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 0 10px;
        }
      }
    }
  }
}
