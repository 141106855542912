@import '../../styles/helpers/index.scss';

.cta {
  margin: 140px 0 0;
  background-color: map-get($colors, 'background');

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__inner {
    @include align-center;
    &--image {
      margin: -60px 0 0;
      position: relative;
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    &--content {
      margin: 0 0 0 85px;

      @media screen and (max-width: $tablet-sm) {
        padding: 30px 0;
        text-align: center;
        margin: 0 auto;
      }

      a {
        margin: 40px 0 0;
        @include scale;

        @media screen and (max-width: $tablet-sm) {
          margin: 30px 0 0;
        }

        &::before {
          background-image: url('../../static/images/icons/phone_white.svg') !important;

          @media screen and (max-width: $tablet-sm) {
            display: none;
          }
        }
      }
    }
  }
}
